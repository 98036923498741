// styles
import 'react-gallery-carousel/dist/index.css'; // carousel css file
import '../src/components/styles/style.scss';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import React, { Suspense } from 'react';
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
  useLocation,
} from 'react-router-dom';
import { createTheme, ThemeProvider } from '@mui/material/styles';

// loader
import LazyLoader from './components/loader/LazyLoader';
import UpdateEmails from './components/updateEmail/UpdateEmails';
import ARExplrar from './components/arExplrar/arExplrar';
import CustomerSupport from './components/customerSupport/customerSupport';
import DownloadApp from './components/downloadApp/DownloadApp';
import BlogPage from './components/blogPage/BlogPage';
import BlogPageDetails from './components/blogPageDetails/BlogPageDetails';
import OurStory from './components/ourStory';
import PrivacyPolicy from './components/privacyPolicy';
import TermsOfServices from './components/termsOfServices';
import { FlightDetailsProvider } from './context/flightContext';
import Header from './components/header/header';
import Footer from './components/footer/footer';
import { Toaster } from 'react-hot-toast';
import HotelReceiptTemplate from './utils/templates/hotel.booking.receipt';
import HotelBookingConfirmDownload from './utils/templates/HotelBookingConfirmDownload';

// import Carsearchresult from './components/transport/cars/car-search-result'
// screens
// home
const HomePage = React.lazy(() => import('./components/dashboard/HomePage'));
const CancelFlightPage = React.lazy(
  () => import('./components/flights/CancelFlightPage')
);
const Profile = React.lazy(() => import('./components/dashboard/Profile'));
const Cart = React.lazy(() => import('./components/dashboard/Cart'));
const ProfileInfo = React.lazy(
  () => import('./components/dashboard/sections/ProfileInfo')
);
const ChangeConfirmation = React.lazy(
  () => import('./components/flights/ChangeConfirmation')
);
const FlightChangeTicketPage = React.lazy(
  () => import('./components/flights/FlightChangeTicketPage')
);
const FlightChangePayment = React.lazy(
  () => import('./components/flights/FlightChangePayment')
);

const EliterTier = React.lazy(
  () => import('./components/dashboard/sections/EliterTier')
);
const ExplrarBucks = React.lazy(
  () => import('./components/dashboard/sections/ExplrarBucks')
);
const UpdatePassword = React.lazy(
  () => import('./components/security/UpdatePassword')
);
const NewPassword = React.lazy(
  () => import('./components/security/NewPassword')
);
const ValidateProfile = React.lazy(
  () => import('./components/security/ValidateProfile')
);
const EarnedBucks = React.lazy(() => import('./ui/bucks/AvailableBucks'));
const ReedemedBucks = React.lazy(() => import('./ui/bucks/ReedemedBucks'));
const ExpiredBucks = React.lazy(() => import('./ui/bucks/ExpiredBucks'));
const EmailVerificationRequest = React.lazy(
  () => import('./components/updateEmail/EmailVerificationRequest')
);
const ManageBooking = React.lazy(
  () => import('./components/dashboard/sections/bookings/ManageBooking')
);
const PathValidator = React.lazy(
  () => import('./utils/Pathvalidators/PathValidator')
);
const FrequentTraveler = React.lazy(
  () =>
    import('./components/dashboard/sections/frequenttraveler/FrequentTraveler')
);
const PaymentMethod = React.lazy(
  () => import('./components/dashboard/sections/PaymentMethod')
);
const AccountSecurity = React.lazy(
  () => import('./components/dashboard/sections/AccountSecurity')
);

// login
const LoginPage = React.lazy(() => import('./components/login/loginPage'));
const SignupPage = React.lazy(() => import('./components/signup/signupPage'));
const ResetPasswordPage = React.lazy(
  () => import('./components/resetpassword/resetPasswordPage')
);
const UpdatePasswordPage = React.lazy(
  () => import('./components/updatepassword/updatePasswordPage')
);
const PasswordOption = React.lazy(
  () => import('./components/security/PasswordOption')
);

// admin
const SubscribeList = React.lazy(
  () => import('./components/subscribe/subsribeList')
);
// trip
const MyTrip = React.lazy(() => import('./components/trip/MyTrips'));
const GeneratedTrip = React.lazy(
  () => import('./components/trip/GeneratedTrip')
);
const MyTripDetails = React.lazy(
  () => import('./components/trip/MyTripDetails')
);
const PlaceDetails = React.lazy(
  () => import('./components/trip/ActivityDetails')
);
// flights
const FlightSearch = React.lazy(
  () => import('./components/flights/FlightSearchPage')
);
const FlightResult = React.lazy(
  () => import('./components/flights/FlightResultPage')
);
const FlightBooking = React.lazy(
  () => import('./components/flights/fligthBookingPage')
);
const FlightsBookingConfirm = React.lazy(
  () => import('./components/flights/flightsBookingConfirm')
);
const FlightBookingFailed = React.lazy(
  () => import('./components/flights/FlightBookingFailed')
);
// const FlightBookDetails = React.lazy(() => import('./components/flights/FlightBookDetails'))
const ChangeFlight = React.lazy(
  () => import('./components/flights/ChangeFlight')
);
const FlightBookingDetail = React.lazy(
  () => import('./components/flights/FlightBookingDetail')
);
const FlightBookingReceipt = React.lazy(
  () => import('./utils/templates/flight.booking.receipt')
);

const CancelFlightPageConfirm = React.lazy(
  () => import('./components/flights/CancelFlightPageConfirm')
);

const HotelBookingReceipt = React.lazy(
  () => import('./utils/templates/hotel.booking.receipt')
);
const HotelSearch = React.lazy(
  () => import('./components/hotel/hotelSearchPage')
);
const HotelResult = React.lazy(
  () => import('./components/hotel/hotelResultPage')
);
const HotelMapPage = React.lazy(
  () => import('./components/hotel/hotelMapPage')
);
const HotelView = React.lazy(() => import('./components/hotel/hotelViewPage'));
const HotelCompare = React.lazy(
  () => import('./components/hotel/hotelComparePage')
);
const HotelBooking = React.lazy(
  () => import('./components/hotel/hotelBookingPage')
);
const HotelBookingConfirm = React.lazy(
  () => import('./components/hotel/hotelBookingConfirm')
);
const HotelBookingDetail = React.lazy(
  () => import('./components/hotel/hotelBookingDetail')
);
const CancelHotelPage = React.lazy(
  () => import('./components/hotel/CancelHotelPage')
);
const CancelHotelPageConfirm = React.lazy(
  () => import('./components/hotel/CancelHotelPageConfirm')
);

// transport
const CarRental = React.lazy(
  () => import('./components/transport/cars/car-rental')
);
const SearchCars = React.lazy(
  () => import('./components/transport/cars/car-search-result')
);
const CarMapPage = React.lazy(
  () => import('./components/transport/cars/car-map-page')
);

// payment
const PaymentSuccess = React.lazy(
  () => import('./components/payment/page/success')
);
const PaymentFailed = React.lazy(
  () => import('./components/payment/page/failed')
);

// Experiences
const EventsPage = React.lazy(
  () => import('./components/experiences/EventsPage')
);
const EventDetailsPage = React.lazy(
  () => import('./components/experiences/EventDetailsPage')
);

// templates
const FlightReceiptTemplate = React.lazy(
  () => import('./utils/templates/flight.booking.receipt')
);
const FlightItineraryOneWayTemplate = React.lazy(
  () => import('./utils/templates/flight.itinerary.oneway')
);

const theme = createTheme({
  typography: {
    fontFamily: 'Poppins, sans-serif',
  },
});

const AppRoutes = () => {
  const location = useLocation();
  const isBookingReceiptPage = location.pathname.includes('getReceipt');
  return !isBookingReceiptPage ? (
    <>
      {/* ADD all your routes here only */}
      <Header />
      <FlightDetailsProvider>
        <Suspense fallback={<LazyLoader />}>
          <Routes>
            <Route path="*" element={<HomePage />} />
            <Route
              path="profile"
              element={
                <ValidateProfile>
                  <Profile />
                </ValidateProfile>
              }
            >
              <Route index element={<Navigate replace to="myprofile" />} />
              <Route path="myprofile" element={<ProfileInfo />} />
              <Route path="mytrip" element={<ProfileInfo />} />
              <Route path="bookings" element={<ManageBooking />} />
              <Route path="elite" element={<EliterTier />} />
              <Route path="explrerbucks" element={<ExplrarBucks />}>
                <Route index element={<Navigate replace to="earned" />} />
                <Route path="earned" element={<EarnedBucks />} />
                <Route path="reedemed" element={<ReedemedBucks />} />
                <Route path="expired" element={<ExpiredBucks />} />
              </Route>
              <Route path="frequent-travelers" element={<FrequentTraveler />} />
              <Route path="account" element={<AccountSecurity />} />
              <Route
                path="account/emailverification"
                element={<EmailVerificationRequest />}
              />
              <Route
                path="account/updateEmail"
                element={
                  <PathValidator>
                    <UpdateEmails />
                  </PathValidator>
                }
              />
              <Route path="account/password" element={<PasswordOption />} />
              <Route
                path="account/password/updatemypassword"
                element={<UpdatePassword />}
              />
              <Route
                path="account/password/resetPassword"
                element={
                  <PathValidator>
                    <NewPassword />
                  </PathValidator>
                }
              />
            </Route>
            <Route path="/cart" element={<Cart />} />
            {/* Login */}
            <Route path="/login" element={<LoginPage />} />
            <Route path="/sign-up" element={<SignupPage />} />
            <Route path="/reset-password" element={<ResetPasswordPage />} />
            <Route path="/update-password" element={<UpdatePasswordPage />} />
            <Route
              path="/dashboard/users/subscribe/list"
              element={<SubscribeList />}
            />
            {/* Trip */}
            <Route path="/trip/my-trip" element={<MyTrip />} />
            <Route
              path="/trip/generated-trip/:tripId"
              element={<GeneratedTrip />}
            />
            <Route
              path="/trip/my-trip/:userId/:tripId"
              element={<MyTripDetails />}
            />
            <Route path="/place/:placeId" element={<PlaceDetails />} />
            {/* Flights */}
            <Route
              path="/flights/booking-detail/:bookingId"
              element={<FlightBookingDetail />}
            />
            <Route
              path="/flights/booking-receipt"
              element={<FlightBookingReceipt />}
            />
            <Route path="/flights" element={<FlightSearch />} />
            <Route path="/flights/search" element={<FlightResult />} />
            <Route path="/flights/booking" element={<FlightBooking />} />
            <Route
              path="/flights/booking/confirm"
              element={<FlightsBookingConfirm />}
            />
            <Route
              path="/flights/booking/error"
              element={<FlightBookingFailed />}
            />
            {/* <Route path="/flights/booking/details" element={<FlightBookDetails />} /> */}
            <Route
              path="/flights/change-flight/:bookingId"
              element={<ChangeFlight />}
            />
            <Route
              path="/flights/change-flight-confirm"
              element={<ChangeConfirmation />}
            />
            <Route
              path="/flights/change-flight/booking"
              element={<FlightChangePayment />}
            />
            <Route
              path="/flights/cancel-flight/:bookingId"
              element={<CancelFlightPage />}
            />
            <Route
              path="/flights/cancel-flights/confirm"
              element={<CancelFlightPageConfirm />}
            />
            <Route
              path="/flights/change-flights/confirmBooking"
              element={<FlightChangeTicketPage />}
            />

            {/* Hotels */}
            <Route path="/hotels" element={<HotelSearch />} />
            <Route path="/hotels/search" element={<HotelResult />} />
            <Route path="/hotels/map" element={<HotelMapPage />} />
            <Route path="/hotels/view" element={<HotelView />} />
            <Route path="/hotels/compare" element={<HotelCompare />} />
            <Route path="/hotels/booking" element={<HotelBooking />} />
            <Route
              path="/hotels/booking/confirm"
              element={<HotelBookingConfirm />}
            />
            <Route
              path="/hotels/booking-detail/:bookingId"
              element={<HotelBookingDetail />}
            />
            <Route
              path="/hotels/cancel-hotel/:bookingId"
              element={<CancelHotelPage />}
            />
            <Route
              path="/hotels/cancel-hotels/confirm"
              element={<CancelHotelPageConfirm />}
            />
            <Route
              path="/hotels/booking-receipt"
              element={<HotelBookingReceipt />}
            />

            {/*Transport*/}
            <Route path="/transport/cars" element={<CarRental />} />
            <Route path="/cars/search" element={<SearchCars />} />
            <Route path="/cars/map" element={<CarMapPage />} />
            {/* payment success */}
            <Route path="/payment/success" element={<PaymentSuccess />} />
            <Route path="/payment/failed" element={<PaymentFailed />} />
            {/* Experiences */}
            <Route path="/events" element={<EventsPage />} />
            <Route
              path="/events/details/:eventId"
              element={<EventDetailsPage />}
            />
            <Route path="/arexplrar" element={<ARExplrar />} />
            <Route path="/customer-support" element={<CustomerSupport />} />
            {/* <Route path="/package-list" element={<PackageList />} /> */}
            {/* <Route path="/package-list/:packageId" element={<PackListItinerary />} /> */}
            <Route path="/download-app" element={<DownloadApp />} />
            <Route path="/blog-page" element={<BlogPage />} />
            <Route path="/blog-page-details" element={<BlogPageDetails />} />
            <Route path="/our-story" element={<OurStory />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/terms-service" element={<TermsOfServices />} />
          </Routes>
          {/* <ChatBot /> */}
        </Suspense>
      </FlightDetailsProvider>
      <Footer />
    </>
  ) : (
    <Routes>
      <Route
        path="/getReceipt/hotel/booking-receipt/:bookingId"
        element={<HotelReceiptTemplate />}
      />
      <Route
        path="/getReceipt/hotel/booking-confirm/:bookingId"
        element={<HotelBookingConfirmDownload />}
      />
      <Route
        path="/getReceipt/booking-receipt/:bookingId"
        element={<FlightReceiptTemplate />}
      />
      <Route
        path="/getReceipt/oneway-itinerary/:bookingId"
        element={<FlightItineraryOneWayTemplate />}
      />
    </Routes>
  );
};

function App() {
  return (
    <>
      <ThemeProvider theme={theme}>
        <Toaster />
        <BrowserRouter>
          <AppRoutes />
        </BrowserRouter>
      </ThemeProvider>
    </>
  );
}

export default App;

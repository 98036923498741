import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import {
  getUserProfile,
  setProfileSubPage,
  getIpData,
  setPreferedCurrency,
  setExchangeRateForPreferedCurrency,
} from '../../redux/userState';
// navigator
import { useNavigate } from 'react-router-dom';
import LoadingBar from 'react-top-loading-bar';
import { validateBucks, getExchangeRate } from '../../services/service';
import Skeleton from '@mui/material/Skeleton';
import CurrencyPopup from '../popup/currency';
import { useRef } from 'react';

function Header(props) {
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const currencyValue = localStorage.getItem('currency');
  const [currency, setCurrency] = useState(
    currencyValue ? currencyValue : 'USD'
  );
  const [tabName, setTabName] = useState('');
  const navigate = useNavigate();
  const profileBtnRef = useRef(null);
  const showNav = () => {
    document.getElementById('trip-gen')?.classList.toggle('setIndex');
    let shownav = document.querySelector('.bg-xsm-xl-black');
    let line1 = document.querySelector('.line-1');
    let line2 = document.querySelector('.line-2');
    let line3 = document.querySelector('.line-3');
    shownav?.classList.toggle('shownav');
    line2?.classList.toggle('line2');
    line1?.classList.toggle('line1');
    line3?.classList.toggle('line3');
  };
  const setCurrecyHandle = async (curr) => {
    setCurrency(curr);
    dispatch(setPreferedCurrency(curr));
    localStorage.setItem('currency', curr);
    try {
      const data = await getExchangeRate({ preferedCurrency: curr });
      dispatch(setExchangeRateForPreferedCurrency(data.conversion_rates));
      localStorage.setItem(
        'exchangeRates',
        JSON.stringify(data.conversion_rates)
      );
    } catch (error) {
      console.log(
        'failed to fetch the exchange rates for the current currency code: ',
        error
      );
    }
    handleCloseCurrencyPopup();
  };

  useEffect(() => {
    localStorage.setItem('currency', currency);
    if (!localStorage.getItem('exchangeRates')) {
      setCurrecyHandle(currency);
    }
  }, [currency]);

  useEffect(() => {
    if (!user.ipData?.city) {
      dispatch(getIpData());
    }
  }, [dispatch, user.ipData?.city]);

  useEffect(() => {
    if (!localStorage.getItem('isUserLoggedIn')) {
      const urlString = window.location.href;
      const searchParams = new URLSearchParams(new URL(urlString).search);
      const userId = searchParams?.get('userId');
      const code = searchParams?.get('code');
      const emailPromt = searchParams?.get('emailPromt');
      if (userId && code) {
        localStorage.setItem('userId', userId);
        localStorage.setItem('access_token', code);
        localStorage.setItem('isUserLoggedIn', 'true');
        dispatch(getUserProfile());
        if (emailPromt) {
          navigate('/profile/account/updateEmail');
        } else {
          navigate('/');
        }
      }
    } else {
      dispatch(getUserProfile());
    }
  }, []);

  useEffect(() => {
    const validationOfBucks = async () => {
      await validateBucks({
        access_token: localStorage.getItem('access_token'),
      });
    };
    if (localStorage.getItem('access_token')) validationOfBucks();
  }, []);

  const [openCurrencyPopup, setOpenCurrencyPopup] = useState(false);
  const handleOpenCurrencyPopup = () => {
    setOpenCurrencyPopup(true);
  };

  const handleCloseCurrencyPopup = () => {
    setOpenCurrencyPopup(false);
  };
  const handleClickOutside = (event) => {
    if (
      profileBtnRef.current &&
      !profileBtnRef.current.contains(event.target)
    ) {
      setTabName('');
    }
  };

  useEffect(() => {
    if (tabName === 'showProfile') {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [tabName]);
  return (
    <header>
      <LoadingBar color="#FDA400" progress={user.progress} />
      <nav>
        <div className="container">
          <div className="d-flex align-items-center position-relative justify-content-between flex-wrap py-3 my-md-1">
            <div className="d-flex align-items-center header-nav">
              <div
                onClick={showNav}
                className="d-flex flex-column d-xl-none me-3 menu"
                style={{ cursor: 'pointer' }}
              >
                <span className="line-1" />
                <span className="line-2" />
                <span className="line-3" />
              </div>
              <Link to={'/'}>
                <img
                  className="ms-1 nav-logo p-pointer"
                  src="../../../Assets/Img/svg/explrar-logo.svg"
                  alt="explrar-logo"
                />
              </Link>
            </div>

            <ul className="ps-0 mb-0 d-flex align-items-center justify-content-between menu-items">
              <li
                className="position-relative ms-lg-4 ps-20"
                style={{ padding: 0 }}
              >
                <div
                  onClick={handleOpenCurrencyPopup}
                  className="d-flex align-items-center justify-content-between"
                >
                  <p className="fs-16 fw-500 ff-inter color-blue-black mb-0 opacity-70">
                    {currencyValue}
                  </p>
                  <img
                    className="ms-1 ms-lg-2 w-xsm-21"
                    src="../../../Assets/icons/drop-down-arrow.svg"
                    alt="drop-down-arrow"
                  />
                </div>
              </li>
              {openCurrencyPopup && (
                <CurrencyPopup
                  setCurrencyHandle={setCurrecyHandle}
                  open={openCurrencyPopup}
                  handleClickOpen={handleOpenCurrencyPopup}
                  handleClose={handleCloseCurrencyPopup}
                />
              )}
              <li className="ms-3 ms-md-4 ps-20 d-none d-xl-block">
                <Link
                  to="/download-app"
                  className="fs-16 fw-500 ff-inter color-blue-black opacity-70"
                >
                  Download App
                </Link>
              </li>
              {/* after sign in */}
              {localStorage.getItem('isUserLoggedIn') ? (
                <li
                  className="ms-2 ms-sm-3 ms-lg-4 ps-sm-2 position-relative profile-btn"
                  style={{ minWidth: '24px' }}
                  ref={profileBtnRef}
                >
                  <div
                    onClick={() => {
                      if (tabName === 'showProfile') {
                        setTabName('');
                      } else {
                        setTabName('showProfile');
                      }
                    }}
                    onMouseEnter={() => {
                      setTabName('showProfile');
                    }}
                    className="d-flex align-items-center button-with-icon"
                    style={{ minWidth: '24px' }}
                  >
                    <img
                      className="header-profile-pic"
                      src={
                        user?.userProfile?.data?.user?.profile_pic
                          ? user.userProfile.data.user.profile_pic
                          : '../../../Assets/icons/user-profile-icon.svg'
                      }
                      alt="user-profile-icon"
                    />
                    <p className="fs-17 fw-600 ff-inter color-blue-black d-block ps-2 ms-1 d-none d-md-block">
                      {user?.userProfile?.data?.user ? (
                        `${user && user?.userProfile?.data?.user?.first_name} ${
                          user && user?.userProfile?.data?.user?.last_name
                        }`
                      ) : (
                        <Skeleton
                          variant="text"
                          width={60}
                          sx={{ fontSize: '1rem' }}
                        />
                      )}
                    </p>
                  </div>
                  <div
                    className={
                      tabName === 'showProfile'
                        ? 'position-absolute profile-drop-down d-block'
                        : 'position-absolute profile-drop-down display-none'
                    }
                    style={{ top: '65px' }}
                    onMouseEnter={() => {
                      setTabName('showProfile');
                    }}
                    onMouseLeave={() => {
                      setTabName('');
                    }}
                  >
                    <Link
                      to="/profile"
                      className="d-flex align-items-center justify-content-between color-blue-black nav-dropdown-btn orange-color-hover"
                      onClick={() => {
                        dispatch(setProfileSubPage('profile'));
                        setTabName('');
                      }}
                    >
                      <p className="fs-16 fw-500 ff-inter opacity-70">
                        Profile
                      </p>
                    </Link>

                    <Link
                      className="d-flex align-items-center justify-content-between mt-3 color-blue-black nav-dropdown-btn orange-color-hover"
                      onClick={() => {
                        dispatch(setProfileSubPage('booking'));
                        setTabName('');
                      }}
                      to="/profile/bookings"
                    >
                      <p className="fs-16 fw-500 ff-inter opacity-70">
                        Manage Bookings
                      </p>
                    </Link>
                    <Link
                      className="d-flex align-items-center justify-content-between mt-3 color-blue-black nav-dropdown-btn orange-color-hover"
                      onClick={() => {
                        dispatch(setProfileSubPage('elite'));
                        setTabName('');
                      }}
                      to="/profile/elite"
                    >
                      <p className="fs-16 fw-500 ff-inter opacity-70">
                        Elite Explrar Tiers
                      </p>
                    </Link>
                    <Link
                      className="d-flex align-items-center justify-content-between mt-3 color-blue-black nav-dropdown-btn orange-color-hover"
                      onClick={() => {
                        dispatch(setProfileSubPage('explrerbucks'));
                        setTabName('');
                      }}
                      to="/profile/explrerbucks"
                    >
                      <p className="fs-16 fw-500 ff-inter opacity-70">
                        Explrar Bucks
                      </p>
                    </Link>
                    <Link
                      className="d-flex align-items-center justify-content-between mt-3 color-blue-black nav-dropdown-btn orange-color-hover"
                      onClick={() => {
                        dispatch(setProfileSubPage('frequent-travelers'));
                        setTabName('');
                      }}
                      to="/profile/frequent-travelers"
                    >
                      <p className="fs-16 fw-500 ff-inter opacity-70">
                        Frequent Travelers
                      </p>
                    </Link>
                    <Link
                      className="d-flex align-items-center justify-content-between mt-2 color-blue-black nav-dropdown-btn orange-color-hover pb-4 profile-border-bottm"
                      onClick={() => {
                        dispatch(setProfileSubPage('account'));
                        setTabName('');
                      }}
                      to={'/profile/account'}
                    >
                      <p className="fs-16 fw-500 ff-inter opacity-70">
                        Account Security
                      </p>
                    </Link>
                    <Link
                      to={'/customer-support'}
                      className="d-flex align-items-center justify-content-between mt-3 color-blue-black nav-dropdown-btn orange-color-hover"
                    >
                      <p className="fs-16 fw-500 ff-inter opacity-70">
                        Support
                      </p>
                    </Link>
                    <div
                      className="d-flex align-items-center justify-content-between mt-3 color-blue-black nav-dropdown-btn orange-color-hover"
                      onClick={() => {
                        localStorage.clear();
                        navigate('/');
                        window.location.reload();
                      }}
                    >
                      <p className="fs-16 fw-500 ff-inter opacity-70 text-red">
                        Sign Out
                      </p>
                      <img
                        src="../../../Assets/icons/logout-icon.svg"
                        alt="logout-icon"
                      />
                    </div>
                  </div>
                </li>
              ) : (
                <div style={{ display: 'flex' }}>
                  <div className="d-md-none d-block user-login-wrapper">
                    <Link to={'/login'}>
                      <img
                        src="../../../Assets/icons/user-profile-icon.svg"
                        alt="login-icon"
                      />
                    </Link>
                  </div>
                  <div className="d-none d-md-block">
                    <li className="ms-3 ms-lg-4 ps-lg-2 after-signin-none">
                      <Link
                        to={'/login'}
                        className="fs-17 fw-600 ff-inter color-blue sign-in-btn"
                      >
                        Sign In
                      </Link>
                    </li>
                  </div>
                  <div className="d-none d-md-block">
                    <li className="ms-3 ms-sm-2 ms-lg-3 ps-lg-1 after-signin-none2">
                      <Link
                        to={'sign-up'}
                        className="fs-17 fw-600 ff-inter color-white create-account-btn"
                      >
                        Create Account
                      </Link>
                    </li>
                  </div>
                </div>
              )}
            </ul>
          </div>
          <span className="header_border_line"></span>
          <div className="py-xl-3 mt-xl-1">
            <ul className="ps-0 mb-0 d-xl-flex bg-xsm-xl-black pb-3 pb-xl-0 justify-content-left">
              <li className="position-relative second-nav-border-bottom">
                <div
                  onMouseEnter={() => {
                    setTabName('showTrip');
                  }}
                  className="d-flex justify-content-between align-items-center second-nav-btn"
                >
                  <p className="fs-16 fw-500 ff-inter color-blue-black opacity-70">
                    Trips
                  </p>
                  <img
                    className="d-xl-none trips-dropdown-btn"
                    src="../../../Assets/icons/dropdown-white-btn.svg"
                    alt="dropdown-white-btn"
                  />
                </div>
                <div
                  style={
                    tabName === 'showTrip'
                      ? { display: 'block' }
                      : { display: 'none' }
                  }
                  className="second-nav-dropdown trips"
                  onMouseLeave={() => {
                    setTabName('');
                  }}
                >
                  <div className="d-flex flex-column">
                    <Link
                      className="fs-16 fw-500 ff-inter color-blue-black orange-color-hover opacity-70 nav-dropdown-btn"
                      to={'/'}
                    >
                      Plan a Trip
                    </Link>
                    <Link
                      to={'/trip/my-trip'}
                      className="fs-16 fw-500 ff-inter color-blue-black mt-4 orange-color-hover opacity-70 nav-dropdown-btn"
                    >
                      My Trips
                    </Link>
                  </div>
                </div>
              </li>

              <li className="second-nav-border-bottom">
                <Link to={'/flights'} className="w-100">
                  <div className="second-nav-btn d-block">
                    <p className=" fs-16 fw-500 ff-inter color-blue-black opacity-70">
                      Flights
                    </p>
                  </div>
                </Link>
              </li>
              <li className="second-nav-border-bottom">
                <Link to={'/hotels'} className="w-100">
                  <div className="second-nav-btn d-block">
                    <p className=" fs-16 fw-500 ff-inter color-blue-black opacity-70">
                      Hotels
                    </p>
                  </div>
                </Link>
              </li>
              <li className="second-nav-border-bottom">
                <Link to="/arexplrar" className="second-nav-btn d-block">
                  <p className="fs-16 fw-500 ff-inter color-blue-black opacity-70">
                    AR Explrar
                  </p>
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>
      {/* {props.render} */}
    </header>
  );
}
export default Header;

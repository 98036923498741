const url = process.env.REACT_APP_BACKEND_URL;
// const url = 'http://localhost:3001/v1/';
export default url;

const config = {
  google: {
    apikey: process.env.REACT_APP_GOOGLE_MAPS_API,
    mapID: process.env.REACT_APP_GOOGLE_MAP_ID,
    myTripMAPID: process.env.REACT_APP_GOOGLE_MY_TRIP_MAP_ID,
  },
  paypal: {
    clientId:
      process.env.REACT_APP_PAYPAL_CLIENT_ID ||
      'AYRui1HLSc5a1Ecdy-amHDhE-vGrjRfd4v2ix_EqGeFB2Zosq5Ukc03Hqcoz3ecbtAQISMZKmNYgCWG0',
  },
  stripe: {
    clientId:
      process.env.REACT_APP_STRIPE_KEY ||
      'pk_test_51Ns0FbJEFlT94vRQ9Luhe4QBBtbOY873a5fekA81cmFCUdo4A6RTXD6JpGRSUcji8QAnGnTncMs67XcwPxf6yAzZ00XKFdzmWm',
  },
  ipgeo: process.env.REACT_APP_IP_GEO_API || '15939ca23ea844efa144fc94d72c2855',
  // https://app.abstractapi.com/api/ip-geolocation/support
};

export { config };
